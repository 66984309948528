// Most maps are included here. The only exceptions are the global vars which need
// to be printed as rems or pixels in the theme.scss and theme-rem.scss files
@mixin print-vars-in-maps {

  // ////////////////////////////////
  // MAP file: typography-deprecated
  @each $property, $result in $font-deprecated {
    @each $level, $value in $result {
      @if $level == "default" {
        --#{$prefix}-theme--#{$property}: #{$value};
      } @else if $property == "spacing" {
        --#{$prefix}-theme--#{$level}: #{$value};
      } @else {
        --#{$prefix}-theme--#{$property}--#{$level}: #{$value};
      }
    }
  } 
  /* END DEPRECATED */

  ///////////////////////////////////
  ///  LINE HEIGHT, WEIGHT, FAMILY
  @each $property, $result in $font {
    @each $level, $value in $result {
      @if $level == "default" {
        --#{$prefix}-theme--#{$property}: #{$value};
      } @else {
        --#{$prefix}-theme--#{$property}--#{$level}: #{$value};
      }
    }
  }  

  ///////////////////////////////////
  ///  COLOR

  // MAP file: ui-elements
  @each $key, $value in $color--ui {
    --#{$prefix}-theme--color--#{$key}: #{$value};
  }

  @each $surface, $properties in $color--surface {
      @each $property, $result in $properties {
          @if type-of($result) == "color" and $property == "background" {
              --#{$prefix}-theme--color--surface--#{$surface}: #{$result};
          } @else {
              --#{$prefix}-theme--color--surface--#{$surface}--#{$property}: #{$result};
          }
      }
  }
  ///////////////////////////////////
  ///  BORDERS, LOGO, SHADOW, Z-INDEX
 
  // MAP file: ui-elements
  @each $key, $value in $border--ui {
    --#{$prefix}-theme--ui--border-#{$key}: #{$value};
  }

  // MAP file: surface-colors
  @each $key, $value in $border--surface {
    @if type-of($value) != "map" {
      --#{$prefix}-theme--surface--border-#{$key}: #{$value};
    } 
    @else {
      @each $context, $color in $value {
        @if $context == "default" {
            --#{$prefix}-theme--surface--border: #{$color};
        } @else {
            --#{$prefix}-theme--surface--border-#{$context}: #{$color};
        }
      }
    }
  }
  // MAP file: general
  @each $key, $value in $logo {
    --#{$prefix}-theme--logo--#{$key}: #{$value};
  }
  // MAP file: general
  @each $key, $value in $animation {
    --#{$prefix}-theme--animation-#{$key}: #{$value};
  }
  // MAP file: general
  @each $key, $value in $box-shadow {
    --#{$prefix}-theme--box-shadow--#{$key}: #{$value};
  }
  // MAP file: general
  @each $key, $value in $z-index {
    --#{$prefix}-theme--zindex--#{$key}: #{$value};
  }

  //////////////////////////////
  /// TYPOGRAPHY: COLOR & LINKS
  // MAP file: typography
  @each $theme, $value in $color--text {
    @if $theme == "default" {
      --#{$prefix}-theme--color--text: #{$value};
    } @else {
      --#{$prefix}-theme--color--text--on-#{$theme}: #{$value};
    }
  }

  // MAP file: typography
  @each $theme, $value in $color--text-muted {
   @if $theme == "default" {
     --#{$prefix}-theme--color--text-muted: #{$value};
   }  @else {
     --#{$prefix}-theme--color--text-muted--on-#{$theme}: #{$value};
   }
  }

  // MAP file: typography
  @each $theme, $value in $color--links {
    @each $state, $color in $value {
      $context: "";
      @if $state != "default" {
        $context: "--#{$state}";
      }
      @if $theme == "default" {
        --#{$prefix}-theme--color--link#{$context}: #{$color};
      } @else {
        --#{$prefix}-theme--color--link#{$context}--on-#{$theme}: #{$color};
      }
    }
  }

  // MAP file: typography
  @each $theme, $value in $link-decoration {
    @each $state, $color in $value {
      $context: "";
      @if $state != "default" {
        $context: "--#{$state}";
      }
      @if $theme == "default" {
        --#{$prefix}-theme--link-decoration#{$context}: #{$color};
      } @else {
        --#{$prefix}-theme--link-decoration#{$context}--on-#{$theme}: #{$color};
      }
    }
  }
}

