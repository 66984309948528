@import "functions/_functions";
@import "maps/_typography";
@import "maps/_ui-elements";

//-- COMPONENT SPECIFIC STYLES
:root {
    --pfe-tabs__tab--FontSize: #{map-deep-get($size, title--m, md--FontSize)};
    --pfe-clipboard--FontSize: #{map-deep-get($size, text--m, md--FontSize)}; // temporary fix
    // RH.com pfe-progress-steps styles.
    --pfe-progress-steps-item__circle--color--active: #{map-get($color--ui, ui-accent)};
    --pfe-progress-steps-item__title--Color--active: var(--pfe-broadcasted--text, #{map-get($color--text, default)});
    --pfe-progress-steps__progress-bar--color--active: var(--pfe-theme--color--ui-base, #{map-get($color--ui, ui-base)});
}

pfe-cta {
    // IE11 CTA fix
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        &[pfe-priority="primary"] {
            background-color: #e00;
            border-color: #e00;
        }
    }
}

pfe-navigation {
  --pfe-theme--color--surface--accent: var(--pfe-theme--color--ui-accent, #{map-get($color--ui, ui-accent)});  // temporary fix
  --pfe-theme--container-padding: 14px; // temporary fix
}

pfe-navigation-item {
    --pfe-theme--color--ui-base: var(--pfe-theme--color--ui-accent, #{map-get($color--ui, ui-accent)});  // temporary fix for nav v.51
}

pfe-jump-links-nav {
  --pfe-jump-links--BorderColor: var(--pfe-theme--color--ui-accent, #{map-get($color--ui, ui-accent)}); // temporary fix for jump-links v.50
}
